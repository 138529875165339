

export default [
    {
        id: "lol",
        header: "League of Legends",
        game_card_info: ["25+", "6000+", "20+"],
        game_card_info_2: ["Leagues (LCS, LEC)", "Matches a year", "Markets per game"]
    },
    {
        id: "dota2",
        header: "Dota 2",
        game_card_info: ["100+", "7000+", "55+"],
        game_card_info_2: ["Tournaments (Spring Major)", "Matches a year", "Markets per game"]
    },
    {
        id: "val",
        header: "Valorant",
        game_card_info: ["50+", "9000+", "35+"],
        game_card_info_2: ["Tournaments (Challengers)", "Matches a year", "Markets per game"]
    },
    {
        id: "rl",
        header: "Rocket League",
        game_card_info: ["25+", "8500+", "20+"],
        game_card_info_2: ["Tournaments (LCK, LPL)", "Matches a year", "Markets per game"]
    },
    {
        id: "cod",
        header: "Call of Duty",
        game_card_info: ["25+", "6500+", "20+"],
        game_card_info_2: ["Tournaments (LEC, LCK)", "Matches a year", "Markets per game"]
    },
    {
        id: "csgo",
        header: "CSGO",
        game_card_info: ["25+", "7500+", "45+"],
        game_card_info_2: ["Tournaments (EMS, ESL)", "Matches a year", "Markets per game"]
    }
]