import React from "react";
import { Grid, _ } from 'gridjs-react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import jsSHA from "jssha";
import "../components/Dashboard/css/dashboard.css"
import Layout from "../components/Layout.js";
import league_dash from "../assets/images/dashboard/league_dash.png";
import val_dash from "../assets/images/dashboard/val_dash.png";
import dota2_dash from "../assets/images/dashboard/dota2_dash.png";
import csgo_dash from "../assets/images/dashboard/csgo_dash.png";
import rocket_league_dash from "../assets/images/dashboard/rocket_league_dash.png";
import cod_dash from "../assets/images/dashboard/cod_dash.png";
import nba_dash from "../assets/images/dashboard/nba_dash.png";
import cricket_dash from "../assets/images/dashboard/cricket_dash.png";
import kabaddi_dash from "../assets/images/dashboard/kabaddi_dash.png";
import soccer_dash from "../assets/images/dashboard/soccer_dash.png";
import moment from "moment-timezone";
import { Container } from "react-bootstrap";
import { connect } from 'react-redux';
import { navigate as navigateAction } from '../store/navigationSlice';
import withNavigation from './withNavigation';



class Dashboard extends React.Component {
	constructor(props) {
		super(props);

		if (JSON.parse(window.sessionStorage.getItem('state'))) {
			this.state = JSON.parse(window.sessionStorage.getItem('state'));
			this.updateTimeFrame(this.state.timeframe);
		}
		else {
			this.state = JSON.parse(window.sessionStorage.getItem('state')) || {
				games: [],
				GamesDataisLoaded: false,
				game_type: "",
				timeframe: "",
				password: "",
				password_authenticated: false,
				authentication_message: "Please enter password..."
			};
		}

		this.handleInput = this.handleInput.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleNavigation(path) {
		this.props.navigateAction(path);
	}

	setState(state) {
		window.sessionStorage.setItem('state', JSON.stringify(state));
		super.setState(state);
	}

	handleInput(event) {
		this.setState({
			password: event.target.value,
			authentication_message: "Please enter password..."
		});
	}

	handleSubmit() {
		var hashObj = new jsSHA("SHA-512", "TEXT", { numRounds: 1 });
		hashObj.update(this.state.password);
		var hash = hashObj.getHash("HEX");
		const dashboardPasswords = process.env.REACT_APP_DASHBOARD_PASSWORDS.split(", ");
		if (dashboardPasswords.includes(hash)) {
			this.setState({ authentication_message: "Success! Logging in...", password_authenticated: true });
		} else {
			this.setState({ authentication_message: "Incorrect password. Please try again...", password_authenticated: false });
		}
	}




	updateGameType(game) {
		this.setState({
			password_authenticated: true,
			game_type: game,
			timeframe: "",
			games: [],
			a: [],
			GamesDataisLoaded: false
		});
	}

	getAPIKey(game) {
		return (game === "cricket" || game === "kabaddi") ? process.env.REACT_APP_RIMBLE3_API_KEY : process.env.REACT_APP_RIMBLE_API_KEY;
	}

	updateTimeFrame(time) {
		if (this.state.game_type && this.state.game_type !== "nba" && this.state.game_type !== "soccer") {
			this.setState({ timeframe: time, GamesDataisLoaded: false, password_authenticated: true, game_type: this.state.game_type });
			fetch("https://rimbleanalytics.com/predictions/" + this.state.game_type + "/" + time + "/", {
				method: "GET",
				headers: {
					"x-api-key": this.getAPIKey(this.state.game_type),
				}
			}
			)
				.then((res) => res.json())
				.then((json) => {
					this.setState({
						password_authenticated: true,
						games: json.map(match => ({
							matchid: match.matchid,
							date: match.date,
							time: match.time,
							league: match.league,
							team_1_name: match.team_1_name,
							team_2_name: match.team_2_name,
							teams: match.teams,
							button: _(
								<button onClick={() => this.handleNavigation(`/teams/${this.state.game_type}/${match.team_1_name}/${match.team_2_name}/${time}/${match.matchid}`)}>
									View Match Info
								</button>
							)
						})),
						GamesDataisLoaded: true,
						game_type: this.state.game_type,
						timeframe: this.state.timeframe
					});
				})
		} else if (this.state.game_type && (this.state.game_type === "nba" || this.state.game_type === "soccer")) {
			// fetch from NBA endpoint and set state appropriately for NBA game type

			this.setState({ timeframe: time, GamesDataisLoaded: false, password_authenticated: true, game_type: this.state.game_type });
			fetch("https://kvru1gtful.execute-api.us-east-1.amazonaws.com/prod/?game=" + this.state.game_type, {
				method: "GET",
				headers: {
					"x-api-key": this.getAPIKey(this.state.game_type),
				}
			}
			)
				.then((res) => res.json())
				.then((json) => {
					this.setState({
						password_authenticated: true,
						games: json.Items.map(match => ({
							matchid: match.gameid,
							date: match.date,
							time: 'N/A',
							league: this.state.game_type.toUpperCase(),
							team_1_name: match.team_1_name,
							team_2_name: match.team_2_name,
							teams: match.teams,
							button: _(
								<button onClick={() => this.handleNavigation(`/teams/${this.state.game_type}/${match.team_1_name}/${match.team_2_name}/${time}/${match.matchid}`)}>
									View Match Info
								</button>
							)
						})
						),
						GamesDataisLoaded: true,
						game_type: this.state.game_type,
						timeframe: this.state.timeframe
					});
				})
		}
	}

	displayDate(date) {
		var new_date = new Date(date);
		new_date.setDate(new_date.getDate() + 1)
		const datetoString = new_date.toDateString()
		const new_dates = moment(datetoString).format("LLLL").split(" ")
		const formattedDate = new_dates[0] + " " + new_dates[1] + " " + new_dates[2].replace(",", "")
		return formattedDate
	}

	displayTime(time) {
		time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

		if (time.length > 1) { // If time format correct
			time = time.slice(1); // Remove full string match value
			time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
			time[0] = +time[0] % 12 || 12; // Adjust hours
		}
		return time[0] + time[1] + time[2] + " " + time[5]
	}

	listDates(games) {
		const list_dates = []
		games.map(item => {
			if (list_dates.includes(item.date)) {

			} else {
				list_dates.push(item.date)
			}
		}
		)
		return list_dates;
	}

	matchDate(matchdate) {
		const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
		var date = new Date()
		const day = date.getDate()
		const month = date.getMonth()
		const year = date.getFullYear()
		const formattedDate = year + "-" + months[month] + "-" + day
		if (matchdate === formattedDate) {
			return "TODAY"
		} else {
			if (this.state.timeframe === "upcoming-matches") return "UPCOMING";
			if (this.state.timeframe === "completed-matches") return "COMPLETED"
		}
	}



	render() {

		const { GamesDataisLoaded, games, game_type, timeframe } = this.state;
		if (!this.state.password_authenticated) return (

			<Layout>
				<button onClick={() => this.handleNavigation("/peel-blue-dashboard")}>
					Peel Blue Dashboard
				</button>
				<Container className="dashboard-auth-container">
					<h3 className="auth-message">{this.state.authentication_message}</h3>
					<input
						type="text"
						className="auth-input"
						value={this.state.password}
						onChange={this.handleInput.bind(this)}
						placeholder="Enter your password"
					/>
					<button className="auth-submit-btn" onClick={this.handleSubmit.bind(this)}>
						Submit
					</button>
				</Container>
			</Layout>

		);


		if (!this.state.game_type) return (
			<Layout>
				<button onClick={() => this.handleNavigation("/peel-blue-dashboard")}>
					Peel Blue Dashboard
				</button>
				<h2> Please select a game type....
				</h2>
				{/* <DropdownButton id="dropdown-basic-button" title="Dropdown button" align="end"> */}
				<div className="dashboard-nav">
					<div className="dashboard-timeframe-container">
						<h1 className="dashboard-timeframe-header">Matches</h1>
					</div>
					<div className="gametype">
						<div className="gametype-dash-container">
							<img className="dash-lol" src={league_dash} onClick={() => this.updateGameType("lol")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="dash-dota2" src={dota2_dash} onClick={() => this.updateGameType("dota2")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="dash-csgo" src={csgo_dash} onClick={() => this.updateGameType("csgo")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="dash-val" src={val_dash} onClick={() => this.updateGameType("valorant")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-rocketleague" src={rocket_league_dash} onClick={() => this.updateGameType("rocketleague")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-cod" src={cod_dash} onClick={() => this.updateGameType("cod")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-nba" src={nba_dash} onClick={() => this.updateGameType("nba")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-cricket" src={cricket_dash} onClick={() => this.updateGameType("cricket")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-kabaddi" src={kabaddi_dash} onClick={() => this.updateGameType("kabaddi")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-soccer" src={soccer_dash} onClick={() => this.updateGameType("soccer")}></img>
						</div>
					</div>
				</div>
				{/* <Dropdown.Item onClick={() => this.updateGameType("lol")}>League of Legends || </Dropdown.Item>
					<Dropdown.Item onClick={() => this.updateGameType("dota2")}>Dota 2 || </Dropdown.Item>
					<Dropdown.Item onClick={() => this.updateGameType("csgo")}>CS:GO || </Dropdown.Item>
					<Dropdown.Item onClick={() => this.updateGameType("valorant")}>Valorant</Dropdown.Item>

				</DropdownButton> */}
			</Layout>

		);

		if (!this.state.timeframe) return (
			<Layout>
				<button onClick={() => this.handleNavigation("/peel-blue-dashboard")}>
					Peel Blue Dashboard
				</button>
				<h2> Please select a timeframe....
				</h2>
				{/* <DropdownButton id="dropdown-basic-button" title="Games">
					<Dropdown.Item onClick={() => this.updateGameType("lol")}>League of Legends || </Dropdown.Item>
					<Dropdown.Item onClick={() => this.updateGameType("dota2")}>Dota 2 || </Dropdown.Item>
					<Dropdown.Item onClick={() => this.updateGameType("csgo")}>CS:GO || </Dropdown.Item>
					<Dropdown.Item onClick={() => this.updateGameType("valorant")}>Valorant</Dropdown.Item> */}
				{/* <DropdownButton id="dropdown-basic-button" title="Games" align="end" > */}
				<div className="dashboard-nav">
					<div className="dashboard-timeframe-container">
						<h1 className="dashboard-timeframe-header">{game_type} Matches</h1>
						<div className="dashboard-timeframe">
							<a className="dashboard-timeframe-text" onClick={() => this.updateTimeFrame("upcoming-matches")}>Schedule</a>
							<a className="dashboard-timeframe-text" onClick={() => this.updateTimeFrame("completed-matches")}>Results</a>
						</div>
					</div>
					<div className="gametype">
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-lol" src={league_dash} onClick={() => this.updateGameType("lol")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-dota2" src={dota2_dash} onClick={() => this.updateGameType("dota2")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-csgo" src={csgo_dash} onClick={() => this.updateGameType("csgo")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-val" src={val_dash} onClick={() => this.updateGameType("valorant")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-rocketleague" src={rocket_league_dash} onClick={() => this.updateGameType("rocketleague")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-cod" src={cod_dash} onClick={() => this.updateGameType("cod")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-nba" src={nba_dash} onClick={() => this.updateGameType("nba")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-cricket" src={cricket_dash} onClick={() => this.updateGameType("cricket")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-kabaddi" src={kabaddi_dash} onClick={() => this.updateGameType("kabaddi")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-soccer" src={soccer_dash} onClick={() => this.updateGameType("soccer")}></img>
						</div>
					</div>
				</div>

				{/* </DropdownButton> */}
				{/* <p>Currently selected game: {game_type}</p>
				<DropdownButton id="dropdown-basic-button" title="Timeframes">
					<Dropdown.Item onClick={() => this.updateTimeFrame("completed-matches")}>Completed Matches || </Dropdown.Item>
					<Dropdown.Item onClick={() => this.updateTimeFrame("upcoming-matches")}>Upcoming Matches</Dropdown.Item>
				</DropdownButton> */}
			</Layout>
		);

		if (!this.state.GamesDataisLoaded) return (
			<Layout>
				<h2> Please wait a moment while we fetch the data.... </h2>
			</Layout>
		);
		// insert
		const dates_list = this.listDates(games)
		const dates_today = this.matchDate();
		return (
			<Layout>
				<button onClick={() => this.handleNavigation("/peel-blue-dashboard")}>
					Peel Blue Dashboard
				</button>
				<div className="dashboard-nav">
					<div className="dashboard-timeframe-container">
						<h1 className="dashboard-timeframe-header">{game_type} Matches</h1>
						<div className="dashboard-timeframe">
							<a className="dashboard-timeframe-text" onClick={() => this.updateTimeFrame("upcoming-matches")}>Schedule</a>
							<a className="dashboard-timeframe-text" onClick={() => this.updateTimeFrame("completed-matches")}>Results</a>

						</div>
					</div>
					<div className="gametype">
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-lol" src={league_dash} onClick={() => this.updateGameType("lol")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-dota2" src={dota2_dash} onClick={() => this.updateGameType("dota2")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-csgo" src={csgo_dash} onClick={() => this.updateGameType("csgo")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-val" src={val_dash} onClick={() => this.updateGameType("valorant")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-rocketleague" src={rocket_league_dash} onClick={() => this.updateGameType("rocketleague")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-cod" src={cod_dash} onClick={() => this.updateGameType("cod")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-nba" src={nba_dash} onClick={() => this.updateGameType("nba")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-cricket" src={cricket_dash} onClick={() => this.updateGameType("cricket")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-kabaddi" src={kabaddi_dash} onClick={() => this.updateGameType("kabaddi")}></img>
						</div>
						<div className="gametype-dash-container">
							<img className="gametype-dash dash-soccer" src={soccer_dash} onClick={() => this.updateGameType("soccer")}></img>
						</div>
					</div>
				</div>
				<div className="dash-table">

					<Grid
						className="dash-data"
						data={games}
						columns={[
							'matchid',
							'date',
							'time',
							'league',
							'team_1_name',
							'team_2_name',
							'button'
						]}
						sort={true}
						search={true}
					/>
				</div>
			</Layout>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	navigateAction: (path) => dispatch(navigateAction(path)),
});

export default connect(null, mapDispatchToProps)(withNavigation(Dashboard));
