import { Container, Col, Row } from "react-bootstrap";
import "../PrivacyPolicy/css/privacypolicy.css";

function PrivacyPolicy() {
    return (
        <div className="ourterms" id="policy">
            <Container className="our-vision-container">
                <Col className="our-vision-main" id="our-vision">
                    <Row className="about-header-container text-center">
                        <h1 className="terms-header">Privacy Policy</h1>
                        <p>Effective Date: October 2, 2024</p>
                        <p>Last Updated: October 2, 2024</p>
                    </Row>
                    <Row className="about-header-text">
                        <h4>Introduction</h4>
                        <p>
                            This Privacy Policy describes Rimble's policies and procedures on the collection, use, and disclosure of your information when you use our website (https://rimble.com)
                            and related services (collectively referred to as the "Service"). It informs you about your privacy rights and how the law protects you.
                        </p>
                        <p>
                            We always process personal data in accordance with this Privacy Policy and applicable legislation, such as Regulation (EU) 2016/679 (General Data Protection Regulation or GDPR).
                        </p>
                        <p>
                            By using our Service, you agree to the collection and use of information as described in this Privacy Policy. Unless otherwise defined, terms used in this Privacy Policy have the same meanings
                            as in our Terms and Conditions.
                        </p>

                        <h4>Interpretation and Definitions</h4>
                        <h5>Interpretation</h5>
                        <p>
                            Words where the initial letter is capitalized have meanings defined under the following conditions. These definitions will have the same meaning regardless of whether they appear in singular or plural.
                        </p>
                        <h5>Definitions</h5>
                        <p>For the purposes of this Privacy Policy:</p>
                        <ul>
                            <li><strong>Account</strong>: A unique account created for you to access our Service or parts of our Service.</li>
                            <li><strong>Company</strong>: (referred to as "We", "Us" or "Our" in this Agreement) refers to Rimble, located in San Francisco, California.</li>
                            <li><strong>Cookies</strong>: Small files placed on your device by a website, containing details of your browsing history on that website.</li>
                            <li><strong>Device</strong>: Any device that can access the Service, such as a computer, smartphone, or tablet.</li>
                            <li><strong>Personal Data</strong>: Any information that relates to an identified or identifiable individual.</li>
                            <li><strong>Service</strong>: Refers to the Company's website and online platforms.</li>
                            <li><strong>Service Provider</strong>: Any natural or legal person who processes the data on behalf of the Company.</li>
                            <li><strong>Usage Data</strong>: Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.</li>
                            <li><strong>You</strong>: The individual accessing or using the Service, or the company or legal entity on behalf of which such individual is accessing or using the Service.</li>
                        </ul>

                        <h4>Information Collection And Use</h4>
                        <p>
                            We collect several different types of information for various purposes to provide and improve our Service to you.
                        </p>

                        <h5>Personal Data</h5>
                        <p>
                            While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:
                        </p>
                        <ul>
                            <li>Email address</li>
                            <li>First name and last name</li>
                            <li>Phone number</li>
                            <li>Address, State, Province, ZIP/Postal code, City</li>
                            <li>Cookies and Usage Data</li>
                        </ul>

                        <h5>Usage Data</h5>
                        <p>
                            We may also collect information on how the Service is accessed and used. This Usage Data may include information such as your IP address, browser type, browser version,
                            the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and unique device identifiers.
                        </p>

                        <h5>Tracking & Cookies Data</h5>
                        <p>
                            We use cookies and similar tracking technologies to track activity on our Service and hold certain information.
                        </p>
                        <p>
                            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our Service.
                        </p>

                        <h4>Use of Data</h4>
                        <p>Rimble uses the collected data for the following purposes:</p>
                        <ul>
                            <li>To provide and maintain the Service</li>
                            <li>To notify you about changes to our Service</li>
                            <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                            <li>To provide customer care and support</li>
                            <li>To provide analysis or valuable information so that we can improve the Service</li>
                            <li>To monitor the usage of the Service</li>
                            <li>To detect, prevent, and address technical issues</li>
                        </ul>

                        <h4>Transfer of Data</h4>
                        <p>
                            Your information, including Personal Data, may be transferred to—and maintained on—computers located outside of your state, province, country, or other governmental jurisdiction where
                            the data protection laws may differ from those in your jurisdiction.
                        </p>

                        <h4>Disclosure of Data</h4>
                        <p>
                            We may disclose your Personal Data under certain circumstances, including:
                        </p>
                        <ul>
                            <li>To comply with a legal obligation</li>
                            <li>To protect and defend the rights or property of Rimble</li>
                            <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                            <li>To protect the personal safety of users of the Service or the public</li>
                            <li>To protect against legal liability</li>
                        </ul>

                        <h4>Security of Data</h4>
                        <p>
                            The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure.
                            While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                        </p>

                        <h4>Service Providers</h4>
                        <p>
                            We may employ third-party companies and individuals to facilitate our Service, provide the Service on our behalf, perform Service-related tasks, or assist us in analyzing how our Service is used.
                            These third parties have access to your Personal Data only to perform specific tasks and are obligated not to disclose or use it for any other purpose.
                        </p>

                        <h4>Analytics</h4>
                        <p>
                            We may use third-party Service Providers, such as Google Analytics, to monitor and analyze the use of our Service. You can opt-out of Google Analytics by installing the opt-out browser add-on from Google.
                        </p>

                        <h4>Links to Other Sites</h4>
                        <p>
                            Our Service may contain links to third-party websites. We are not responsible for the privacy practices or content of such websites and advise you to review their Privacy Policies.
                        </p>

                        <h4>Children's Privacy</h4>
                        <p>
                            Our Service does not address anyone under the age of 18. We do not knowingly collect Personal Data from anyone under 18. If you believe your child has provided us with Personal Data,
                            please contact us to have the information removed.
                        </p>

                        <h4>Changes to This Privacy Policy</h4>
                        <p>
                            We may update our Privacy Policy from time to time. Changes will be effective when they are posted on this page. We will notify you of significant changes via email and/or a prominent notice on our Service.
                        </p>

                        <h4>Contact Us</h4>
                        <p>
                            If you have any questions about this Privacy Policy, you can contact us:
                        </p>
                        <ul>
                            <li>By email: support@rimble.io</li>
                            <li>By visiting our website: www.rimble.com</li>
                        </ul>
                    </Row>
                </Col>
            </Container>
        </div>
    );
}

export default PrivacyPolicy;
