import "../HomeHeader/css/home-header.css";
import { Button, Container, Row, Col } from 'react-bootstrap';
import rimble_computer from "../../../assets/images/rimble-computer.png";

function HomeHeader() {
    return (
        <div className="home-header">
            <Container className="home-header-container">
                <Col className="rb-header-text-container">
                    <h1 className="rb-home-heading">Next Generation Esport's Odds and Analytics</h1>
                    <p className="rb-home-text">Sophisticated machine learning models and proprietary raw data to create infrastructure for delightful esports experiences.</p>
                    <Row className="home-header-links">
                        <Button className="rb-button" href="contact-us"
                            onClick={(event) => {
                                event.preventDefault();
                                document.getElementById('contact-us').scrollIntoView(
                                    { behavior: 'smooth' }
                                )
                            }}>
                            Get Started
                        </Button>
                        <Button className="learn-more" href="how-it-works" onClick={(event) => {
                            event.preventDefault();
                            document.getElementById("how-it-works").scrollIntoView(
                                { behavior: 'smooth' }
                            )
                        }}>Learn More</Button>
                    </Row>
                </Col>
                <Col className="rb-home-computer-container">
                    <img src={rimble_computer} className="rb-home-computer" alt="Rimble Computer" />
                </Col>
            </Container>
        </div>
    );
}

export default HomeHeader;
