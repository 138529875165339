import React from "react";
import "../components/Home/OurSolutions/css/our-solutions.css";

const Card = ({ data }) => {
    return (
        <div className="cards">
            <div className="card-info-container" key={data.name}>
                {data.image && (
                    <div className="card-icon-group">
                        <div className="image-container">
                            <img
                                src={data.image[0]}
                                className="card-image"
                                alt={data.image[1]}
                                loading="lazy"
                                decoding="async"
                            />
                        </div>
                        <p className="os-text-header">{data.image[1]}</p>
                    </div>
                )}
                {data.image_2 && (
                    <div className="card-icon-group">
                        <div className="image-container">
                            <img
                                src={data.image_2[0]}
                                className="card-image"
                                alt={data.image_2[1]}
                                loading="lazy"
                                decoding="async"
                            />
                        </div>
                        <p className="os-text-header">{data.image_2[1]}</p>
                    </div>
                )}
                {data.image_3 && (
                    <div className="card-icon-group">
                        <div className="image-container">
                            <img
                                src={data.image_3[0]}
                                className="card-image"
                                alt={data.image_3[1]}
                                loading="lazy"
                                decoding="async"
                            />
                        </div>
                        <p className="os-text-header">{data.image_3[1]}</p>
                    </div>
                )}
                {data.image_4 && (
                    <div className="card-icon-group">
                        <div className="image-container">
                            <img
                                src={data.image_4[0]}
                                className="card-image"
                                alt={data.image_4[1]}
                                loading="lazy"
                                decoding="async"
                            />
                        </div>
                        <p className="os-text-header">{data.image_4[1]}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Card;
