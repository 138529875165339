import { Container, Nav } from "react-bootstrap";
import "../MediaReleases/css/mediareleases.css";
import article_one_img from "../../assets/images/media/article_one.jpg";
import article_two_img from "../../assets/images/media/article_two.jpg";
import article_three_img from "../../assets/images/media/article_three.png";
import article_four_img from "../../assets/images/media/article_four.webp";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { navigate as navigateAction } from '../../store/navigationSlice';

function MediaReleases() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        dispatch(navigateAction(path));
        navigate(path);
    };

    return (
        <div className="ourterms" id="ourterms">
            <Container className="our-vision-container">
                <div className="our-vision-main" id="our-vision">
                    <div className="about-header-container text-center">
                        <h1 className="media-header">Newsroom</h1>
                    </div>
                    <div className="press-releases">
                        <h1>Press Releases</h1>
                        <br></br>
                        <div className="press-release-grid">
                            <div className="release">
                                <a className="press-release-link" href="https://www.intergameonline.com/igaming/news/rimble-esports-cricket-betting-offerings-integrate-with-abelson">
                                    <div className="article-img-container">
                                        <img src={article_one_img} className="press-release-img" alt="Rimble esports, cricket betting offerings integrate with Abelson"></img>
                                    </div>
                                    <p>Rimble esports, cricket betting offerings integrate with Abelson</p>
                                </a>
                            </div>
                            <div className="release">
                                <a className="press-release-link" href="https://sbcnews.co.uk/europe/2024/09/20/rimbleabelsoninfointegrate/">
                                    <div className="article-img-container">
                                        <img src={article_two_img} className="press-release-img" alt="Rimble and Abelson Info integrate esports and cricket betting"></img>
                                        <p>Rimble and Abelson Info integrate esports and cricket betting</p>
                                    </div>
                                </a>
                            </div>
                            <div className="release">
                                <a className="press-release-link" href="https://g3newswire.com/rimble-partners-with-abelson-to-bring-enhanced-player-props-to-sportsbooks/">
                                    <div className="article-img-container">
                                        <img src={article_three_img} className="press-release-img" alt="Rimble partners with Abelson to bring enhanced player props to sportsbooks"></img>
                                        <p>Rimble partners with Abelson to bring enhanced player props to sportsbooks</p>
                                    </div>
                                </a>
                            </div>
                            <div className="release">
                                <a className="press-release-link" href="https://sigma.world/news/abelson-info-and-rimble-partner-to-elevate-esports-betting-markets/">
                                    <div className="article-img-container">
                                        <img src={article_four_img} className="press-release-img" alt="Abelson Info and Rimble partner to elevate esports betting markets"></img>
                                        <p>Abelson Info and Rimble partner to elevate esports betting markets</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="articles">
                        <h1>Articles</h1>
                        <ul>
                            <li>
                                <Nav.Link className="navbar-link" onClick={() => handleNavigation('/analyticsimportance')}>The Power of Analytics in Esports: A Game Changer</Nav.Link>
                            </li>
                            <li>
                                <Nav.Link className="navbar-link" onClick={() => handleNavigation('/esportsbettingexplained')}>Esports Betting Explained: A Look at the Trends & Challenges</Nav.Link>
                            </li>
                            <li>
                                <Nav.Link className="navbar-link" onClick={() => handleNavigation('/esportsrisksmechanics')}>Esports Betting: Navigating the Risks with Rimble</Nav.Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container >
        </div >
    );
}

export default MediaReleases;
