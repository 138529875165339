import Layout from "../components/Layout.js"
import OurVision from "../components/About/our-vision.js";
import OurTeam from "../components/About/our-team.js";
import { Helmet } from 'react-helmet';
import "../components/About/css/about.css"

function About() {
    return (
        <Layout>
            <Helmet>
                <title>About</title>
                <meta name="about" content="We created Rimble to provide more accurate data to the esport's industry" />
            </Helmet>
            <div className="about">
                <h1 className="about-header">
                    We created Rimble to provide more accurate data to the esport's industry
                </h1>
            </div>
            <OurVision />
            <OurTeam />
        </Layout>
    )
}

export default About;
