import { configureStore } from '@reduxjs/toolkit';
import navigationReducer from './navigationSlice'; // Ensure this file exists

const store = configureStore({
    reducer: {
        navigation: navigationReducer, // Add your navigation slice
    },
});

export default store;
