import Layout from "../components/Layout.js"
import MediaReleases from "../components/MediaReleases/mediareleases.js";
import "../components/MediaReleases/css/mediareleases.css"
import { Helmet } from 'react-helmet';

function Media() {
    return (
        <Layout>
            <Helmet>
                <title>Media</title>
                <meta name="about" content="Check out our press releases and articles" />
            </Helmet>
            <MediaReleases />
        </Layout>
    )
}

export default Media;