import { createSlice } from '@reduxjs/toolkit';

const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        currentRoute: '/',
    },
    reducers: {
        navigate: (state, action) => {
            state.currentRoute = action.payload; // Update the current route
        },
    },
});

export const { navigate } = navigationSlice.actions;
export default navigationSlice.reducer;
