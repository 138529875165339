import React, { useState, useCallback, memo } from "react";
import OurSolutionsData from "../OurSolutions/OurSolutionsData.js";
import Card from "../../Card.js";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import "../OurSolutions/css/our-solutions.css";

function OurSolutions() {
    const [infoCard, setInfoCard] = useState("Bookmaker");

    const toggleInfoCard = useCallback((name) => {
        setInfoCard(name);
    }, []);

    return (
        <div className="our-solutions">
            <Helmet>
                <meta name="solutions" content="No matter what your company focuses on, we have a solution for you" />
            </Helmet>
            <Container className="our-solutions-container">
                <Row className="os-header-container">
                    <p className="os-header">OUR SOLUTIONS</p>
                    <p className="os-header-2">Products suited to your needs</p>
                    <p className="os-header-text">
                        No matter what your company focuses on, we have a solution for you.
                    </p>
                </Row>
                <Row className="os-content">
                    <Col md={4} className="os-button-group">
                        {["Bookmaker", "Daily Fantasy Sports", "Media Fan Engagement", "Tournament Organizer"].map((solution, index) => (
                            <button
                                key={index}
                                className={`os-button ${infoCard === solution ? "active" : ""}`}
                                onClick={() => toggleInfoCard(solution)}
                            >
                                {solution}
                            </button>
                        ))}
                    </Col>
                    <Col md={8} className="os-card">
                        <Card data={OurSolutionsData[infoCard]} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default memo(OurSolutions);
