import './App.css';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LandingPage from "./pages/LandingPage.js";
import About from './pages/About.js';
import Terms from './pages/Terms.js';
import Dashboard from './pages/Dashboard.js';
import Teams from './pages/Teams.js';
import Players from './pages/Players.js';
import "@fontsource/dm-sans";
import React from 'react';
import PBDashboard from './pages/PBDashboard';
import PBMatch from './pages/PBMatch';
import Privacy from './pages/Privacy.js';
import Media from './pages/Media.js';
import ArticleOne from './pages/ArticleOne.js';
import ArticleTwo from './pages/ArticleTwo.js';
import ArticleThree from './pages/ArticleThree.js';

function App() {
  const currentRoute = useSelector((state) => state.navigation.currentRoute);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentRoute) {
      navigate(currentRoute);
    }
  }, [currentRoute, navigate]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/media" element={<Media />} />
        <Route path="/analyticsimportance" element={<ArticleOne />} />
        <Route path="/esportsbettingexplained" element={<ArticleTwo />} />
        <Route path="/esportsrisksmechanics" element={<ArticleThree />} />
        <Route path="/privacypolicy" element={<Privacy />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/teams/:game_type/:team_1/:team_2/:timeframe/:matchid" element={<Teams />} />
        <Route path="/players/:game_type/:team_1/:team_2/:team_num/:timeframe/:matchid/:player" element={<Players />} />
        <Route path="/peel-blue-dashboard" element={<PBDashboard />} />
        <Route path="/peel-blue-match/:game_type/:matchid/:date/:match_type" element={<PBMatch />} />
      </Routes>
    </div>
  );
}

export default App;
