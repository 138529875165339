import Layout from "../components/Layout.js"
import PrivacyPolicy from "../components/PrivacyPolicy/privacypolicy.js";
import "../components/PrivacyPolicy/css/privacypolicy.css"

function Privacy() {
    return (
        <Layout>
            <PrivacyPolicy />
        </Layout>
    )
}

export default Privacy;