import pre_game_odds from "../../../assets/images/our_solutions/pre_game_odds.png";
import pick_em from "../../../assets/images/our_solutions/pick_em_style_games.png";
import white_label from "../../../assets/images/our_solutions/white_label_solution.png";
import micro_market from "../../../assets/images/our_solutions/in_game_micromarkets.png";
import commentator_dashboard from "../../../assets/images/our_solutions/commentator_dashboards.png";
import pregame from "../../../assets/images/our_solutions/pre_game_and_in_game_predictor_games.png";
import predictor_questions from "../../../assets/images/our_solutions/pre_game_and_in_game_predictor_questions.png";
import embeddable_widgets from "../../../assets/images/our_solutions/embeddable_widgets.png";
import pregame_playerprops from "../../../assets/images/our_solutions/pre_game_player_props.png";
import ingame_playerprops from "../../../assets/images/our_solutions/in_game_player_props.png";
import raw_data from "../../../assets/images/our_solutions/raw_data_for_daily_fantasy_sports.png";


export default {
    "Bookmaker": {
        "name": "Bookmaker",
        "image": [pre_game_odds, "Complete pregame odds", "pre_game_odds"],
        "image_2": [micro_market, "In-game micromarkets", "micro_market"],
        "image_3": [pick_em, "Pick-em style games", "pick_em"],
        "image_4": [white_label, "White label solution", "white_label"]
    },
    "Tournament Organizer": {
        "name": "Tournament Organizer",
        "image": [commentator_dashboard, "Commentator dashboards", "commentator_dashboard"],
        "image_2": [white_label, "White label solution", "white_label"],
        "image_3": [pregame, "Pre-game and in-game predictor games", "pregame"]
    },
    "Media Fan Engagement": {
        "name": "Media Fan Engagement",
        "image": [predictor_questions, "Pre-game and in-game predictor questions", "predictor_questions"],
        "image_2": [embeddable_widgets, "Embeddable widgets", "embeddable_widgets"]

    },
    "Daily Fantasy Sports": {
        "name": "Daily Fantasy Sports",
        "image": [pregame_playerprops, "Pre-game player props", "pregame_playerprops"],
        "image_2": [ingame_playerprops, "In game player props", "ingame_playerprops"],
        "image_3": [raw_data, "Raw data for daily fantasy sports", "raw_data"]
    }
};
