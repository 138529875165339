import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { navigate as navigateAction } from '../../store/navigationSlice';
import "../NavBar/css/Navbar.css";

function CustomNavbar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener, noreferrer');
    };

    const handleNavigation = (path) => {
        dispatch(navigateAction(path));
        navigate(path);
    };

    const toggleNavbar = () => {
        setIsOpen((prevState) => !prevState);
    };

    const handleContactUs = () => {
        handleNavigation('/'); // Update Redux state
        setTimeout(() => {
            // Scroll to the contact section after a slight delay
            const contactSection = document.getElementById('contact-us');
            if (contactSection) {
                contactSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100); // Delay (100ms) to allow the page to load
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="navbar-brand"><a href="/">Rimble</a></div>
                <div className={`navbar-toggle ${isOpen ? 'open' : ''}`} onClick={toggleNavbar}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                <div className={`navbar-links ${isOpen ? 'active' : ''}`}>
                    <Nav.Link className="navbar-link" onClick={() => openInNewTab("https://documenter.getpostman.com/view/16449503/Tzm8FvFw")}>API Documentation</Nav.Link>
                    <Nav.Link className="navbar-link" onClick={() => handleNavigation('/dashboard')}>Dashboard</Nav.Link>
                    <Nav.Link className="navbar-link" onClick={() => handleNavigation('/about')}>About</Nav.Link>
                    <Nav.Link className="navbar-link" onClick={() => handleNavigation('/media')}>Media</Nav.Link>
                    <Nav.Link className="navbar-link" onClick={handleContactUs}>Contact Us</Nav.Link>
                </div>
            </div>
        </nav>
    );
}

export default CustomNavbar;
