import { useNavigate } from 'react-router-dom';

// Create a HOC to inject `navigate` into the class component
function withNavigation(Component) {
    return function (props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}

export default withNavigation;
