import React from "react";
import "./css/gamecard.css";

const GameCard = ({ data }) => {
    return (
        <div className="game-card">
            <h2 className="game-card-title">{data.header}</h2>
            <div className="game-card-info">
                {data.game_card_info.map((info, index) => (
                    <div className="game-card-stat" key={index}>
                        <span className="stat-value">{info}</span>
                        <span className="stat-label">{data.game_card_info_2[index]}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GameCard;
