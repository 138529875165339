import Layout from "../components/Layout.js";
import HomeHeader from "../components/Home/HomeHeader/HomeHeader.js";
import HowItWorks from "../components/Home/HowItWorks/HowItWorks.js";
import OurSolutions from "../components/Home/OurSolutions/OurSolutions.js";
import WhyRimble from "../components/Home/WhyRimble/WhyRimble.js";
import SupportedGames from "../components/Home/SupportGames/SupportGames.js";
import ContactUs from "../components/Home/ContactUs/ContactUs.js";




function LandingPage() {
    return (
        <Layout>
            <div>
                <HomeHeader />
                <HowItWorks />
                <OurSolutions />
                <WhyRimble />
                <SupportedGames />
                <ContactUs />
            </div>
        </Layout>
    );

}

export default LandingPage;