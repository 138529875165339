import Layout from "../components/Layout.js"
import OurTerms from "../components/Terms/ourterms.js";
import "../components/Terms/css/ourterms.css"

function Terms() {
    return (
        <Layout>
            <OurTerms />
        </Layout>
    )
}

export default Terms;